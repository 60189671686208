@tailwind base;
@tailwind components;
@tailwind utilities;

/* ******************** Fonts ******************** */
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/Ubuntu-Bold.woff2') format('woff2');
  font-display: swap;
}  
@font-face {
  font-family: 'Ubuntu-Bold';
  font-style: normal;
  font-weight: 900;
  src: url('./fonts/Ubuntu-Regular.woff2') format('woff2');
  font-display: swap;
}  
:root {
  --main-top: 0rem;
  --main-bottom: 0rem;

  --color2: #426b65;
  --color5: #fdfac7;

  --color-background: #ffffff;
  --color-background-hober: #333;
  --color-text: #1d1e20;
  --color-block: #f6f5f4;

  --font-family: 'Ubuntu';
  --font-family-bold: 'Ubuntu-Bold';
}
*,
*::before,
*::after {
    box-sizing: border-box;
}
html {
  margin: 0;
  font-size: 100%;
  text-size-adjust: 100%;
  scroll-behavior: smooth;
}
body {
  margin: 0;
  width: 100%;
  min-height: 100vh;
  font-family: var(--font-family);
  font-size: clamp(1rem, calc(1rem + 0.5vw), 1.25rem);
  line-height: 1.5;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;

  background-color: var(--color-background);
  color: var(--color-text);
}
code {
  font-family: var(--font-family);
}
a {
  transition: all 0.3s ease-out;
  text-decoration: none;
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; 
} 
a:focus {
  outline: none !important;
  box-shadow: none !important;
}
a:hover {
  color: var(--color2) !important;
}
img,
picture,
video,
canvas,
svg {
  max-width: 100%;
  height: auto;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  margin-bottom: 10px;
  font-family: var(--font-family);
  line-height: 1.2;
  overflow-wrap: break-word;
}
h1 {
  font-size: 34px;
} 
h2 {
  font-size: 30px;
} 
h3 {
  font-size: 24px;
} 
h4 {
  font-size: 20px;
} 
h5 {
  font-size: 18px;
} 
h6,
small {
  font-size: 14px;
}
p {
  font-family: var(--font-family);
  font-size: 16px;
  overflow-wrap: break-word;
  padding-bottom: 6px;
} 
ul {
  list-style: none;
  margin-bottom: 0;
}
nav {
  display: block; 
}
nav ul {
  list-style: none; 
}
main {
  margin-top: var(--main-top);
  margin-bottom: calc(2 * var(--main-bottom));
}
/* ******************** Button CSS ******************** */
.button {
  display: inline-flex;
  font-family: var(--font-family-bold);
  font-weight: 800;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 2px;
  text-decoration: none !important;
  text-transform: uppercase;
  border-radius: 8px;
  cursor: pointer;
  justify-content: center;
  padding: 12px 22px;
  height: auto;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -ms-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
.button {
  background-color: var(--color-text);
  margin: 15px auto;
  border: 1px solid var(--color2);
  color: var(--color-background);
}
.button:hover {
  background-color: var(--color-background-hober);
  color: var(--color5) !important;
  transform: scale(1.02);
}